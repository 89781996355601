




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Recipients } from '@/types/broadcast';
import { setGlobalFailure, getGlobalFailure, getCurrentProblems, removeAutoanswer } from '@/api';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import { AutoanswersHistory } from '@/types/autoanswer';

@Component({
    name: 'EditAutoanswerSettings',
    components: {
        ConfirmDialog,
    },
})
export default class EditAutoanswerSettings extends Vue {
    dialog = false;
    loading = false;
    globalFailure = false;
    currentProblems: AutoanswersHistory[] = [];
    problemsHeaders = [
        {text: 'Дата добавления', value: 'datetime'},
        {text: 'Адреса', value: 'targets'},
        {text: 'Количество клиентов', value: 'clients_count'},
        {text: 'Кто добавил', value: 'user'},
        {text: 'Действия', value: 'action', sortable: false},
    ];

    async setGlobalFailure(enable: boolean) {
        this.loading = true;
        try {
            await setGlobalFailure(enable);
            this.globalFailure = await getGlobalFailure();
        } finally {
            this.loading = false;
        }
    }

    async created() {
        this.loading = true;
        try {
            this.globalFailure = await getGlobalFailure();
            this.currentProblems = await getCurrentProblems();
        } finally {
            this.loading = false;
        }
    }

    async removeAutoanswer(autoanswer: AutoanswersHistory){
        await removeAutoanswer(autoanswer);
        await this.created()
    }

    async send() {
        //
    }
}
